import $ from "jquery";
import("@styles/components/select2/core.scss");
import("@styles/components/select2/theme-default.scss");
import("swiper/scss");

(async () => {
  const { Swiper } = await import("swiper");
  const { Navigation } = await import("swiper/modules");
  await import("select2");

  $(".product-display--type-1").each(function () {
    const swiper = new Swiper($(this).find(".swiper")[0], {
      modules: [Navigation],
      createElements: true,
      slidesPerView: 1.125,
      spaceBetween: 20,
      navigation: {
        nextEl: $(this).find(".nav--next")[0],
        prevEl: $(this).find(".nav--prev")[0],
      },
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1440: {
          slidesPerView: 5,
        },
      },
    });

    $(this)
      .find("select")
      .select2({
        minimumResultsForSearch: -1,
        width: "style",
      })
      .on("change", function () {
        const category_id = Number($(this).val());
        $(swiper.slides).each(function () {
          $(this)[
            category_id === $(this).data("category-id") ? "show" : "hide"
          ]();
        });
        swiper.update();
      });
  });
})();
